import Link from "next/link";
import { footerMenuList, socialIcons, socialIconsMy } from "../data/footer_data";

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-light-base">
        <div className="container">
          <div className="grid grid-cols-1 gap-x-7 gap-y-14 pt-24 pb-4 md:grid-cols-12">

            {/* <!-- Indonesia --> */}
            <div className="col-span-4 md:col-span-4">
              <h4 className="font-display text-jacarta-700 mb-2 text-sm dark:text-white text-center">
                Head Office
              </h4>
              <p className="dark:text-jacarta-300 mb-6 text-center">
                OHP Plaza 3rd Floor, Jl. Solontongan No.1A, Kota Bandung, Jawa Barat 40264 <br />Indonesia
              </p>
              <h3 className="font-display text-jacarta-700 text-sm dark:text-white text-center mb-2">
                Indonesia Office Queries
              </h3>
              <div className="text-center mb-6">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:contact@kiglive.id"
                  className="group cursor-pointer lowercase text-center">
                  contact@kiglive.id
                </a><br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:press@kiglive.id"
                  className="group cursor-pointer lowercase text-center">
                  press@kiglive.id
                </a>
              </div>
              <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white text-center mt-2">KIG Live Indonesia Socials</h3>
              <div className="flex space-x-5 items-center justify-center">
                {socialIcons.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <Link legacyBehavior href={href} key={id}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group cursor-pointer"
                      >
                        <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                          <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>

            {/* <!-- Malaysia --> */}
            <div className="col-span-4 md:col-span-4">

              <h4 className="font-display text-jacarta-700 mb-2 text-sm dark:text-white text-center">
                Branch Office
              </h4>
              <p className="dark:text-jacarta-300 mb-6 text-center">
                A-2-2 Street Mall One South Jalan OS, Taman Serdang Perdana, Seksyen 6, 43300 Sri Kembangan Malaysia
              </p>
              <h3 className="font-display text-jacarta-700 text-sm dark:text-white text-center mb-2">
                Malaysia Office Queries
              </h3>
              <div className="text-center mb-6">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:contact@kiglive.my"
                  className="group cursor-pointer lowercase text-center">
                  contact@kiglive.my
                </a><br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:press@kiglive.my"
                  className="group cursor-pointer lowercase text-center">
                  press@kiglive.my
                </a>
              </div>
              <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white text-center mt-2 mb-2">KIG Live Malaysia Socials</h3>
              <div className="flex space-x-5 items-center justify-center">
                {socialIconsMy.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <Link legacyBehavior href={href} key={id}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group cursor-pointer"
                      >
                        <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                          <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>

            {footerMenuList.map((single) => (
              <div
                className={`col-span-4 md:col-span-4 text-center md:text-center`}
                key={single.id}
              >
                <h3 className="font-display text-jacarta-700 mb-2 text-sm dark:text-white">
                  {single.title}
                </h3>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <Link legacyBehavior href={href}>
                          <a className="hover:text-accent dark:hover:text-white">
                            {text}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 pb-8 sm:flex-row sm:space-y-0 pt-8 mb:pt-0">
            <span className="dark:text-jacarta-400 text-sm">
              © Copyright {new Date().getFullYear()} KIG Live
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link legacyBehavior href="/terms">
                  <a className="hover:text-accent dark:hover:text-white">
                    Terms and conditions
                  </a>
                </Link>
              </li>
              <li>
                <Link legacyBehavior href="/privacy-policy">
                  <a className="hover:text-accent dark:hover:text-white">
                    Privacy policy
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
