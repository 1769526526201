const footerMenuList = [
  {
    id: 1,
    title: "Other Links",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/about-us",
        text: "About Us",
      },
      {
        id: 2,
        href: "/terms",
        text: "Term & Conditions",
      },
      {
        id: 3,
        href: "privacy-policy",
        text: "Privacy Policy",
      },
      {
        id: 4,
        href: "contact-us",
        text: "Contact Us",
      }
    ],
  }
];

const socialIcons = [
  {
    id: 1,
    href: "https://web.facebook.com/kiglive.id",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://twitter.com/kigliveid",
    text: "twitter",
  },
  {
    id: 4,
    href: "https://www.instagram.com/kiglive.id/",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.tiktok.com/@kiglive.id/",
    text: "tiktok",
  },
];

const socialIconsMy = [
  {
    id: 1,
    href: "https://www.facebook.com/kiglive.my",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://twitter.com/kiglivemy",
    text: "twitter",
  },
  {
    id: 4,
    href: "https://www.instagram.com/kiglive.my/",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.tiktok.com/@kiglivemy",
    text: "tiktok",
  },
];


export { footerMenuList, socialIcons, socialIconsMy };
